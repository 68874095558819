<template>
  <editor
    :initial-value="editorText"
    :options="editorOptions"
    height="500px"
    initial-edit-type="wysiwyg"
    preview-style="vertical"
  />
</template>
<script>
import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'

import { Editor } from '@toast-ui/vue-editor'
import defaultOptions from './ToastConfig'

export default {
  components: {
    editor: Editor
  },
  data() {
    return {
      editorText: 'This is initialValue.',
      editorOptions: defaultOptions
      // editorOptions: {
      //   hideModeSwitch: true
      // }
    }
  }
}
</script>
